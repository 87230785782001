// global imports

// import * as Sentry from '@sentry/browser'

import './index.sass'
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import 'bootstrap-sass/assets/javascripts/bootstrap';
import 'alto-react-components/lib/localization'

import reportWebVitals from './reportWebVitals'
// import config from './config'

// app

import   React                     from 'react'
import   ReactDOM                  from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import { Alert                   } from 'react-bootstrap'

// sentry integration
// if (config.sentryDsn) {
//   Sentry.init({dsn: config.sentryDsn, environment: config.deployType})

//   if (config.deployName) {
//     Sentry.configureScope(scope => scope.setTag('deploy_name', config.deployName))
//   } else {
//     Sentry.withScope(() =>
//       Sentry.captureMessage('The "KREDIT_DEPLOY_NAME" environment variable should be provided', 'warning')
//     )
//   }
// }

class MainComponent extends React.Component {
  constructor() {
    super()

    this.state = { RootComponent: null }

    import('./components/root').then(module => {
      this.setState({ RootComponent: module.default })
    })
  }

  render() {
    const { RootComponent } = this.state

    if (RootComponent) {
      return <RootComponent />
    } else {
      return <Alert>Načítám aplikaci</Alert>
    }
  }
}

ReactDOM.render(
  <Router>
    <MainComponent />
  </Router>,
  document.getElementById('root'))

// service worker

// import * as serviceWorker from './serviceWorker'

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.register()

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
